import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

// Sections
import Hero from '../../components/blogetrySections/Hero';
import Featured from '../../components/blogetrySections/Featured';
import Blog from '../../components/blogetrySections/Blog';
import Inbox from '../../components/blogetrySections/InboxSection';

const pageTitle = 'Software Development Blog | Resources, News, & More | Devetry';
const pageDescription =
  'Learn the latest from Devetry! Peruse our blog for articles, downloads, tools, and other free resources for software.';

const BlogetryPageTemplate = ({ featured, blogs, lastResources }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('insights');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container">
        <Hero />
        <Featured data={featured} lastResources={lastResources} />
        <Blog items={blogs} />
        <Inbox />
      </div>
    </>
  );
};

const BlogetryPage = ({ data }) => {
  const { featured } = data.markdownRemark.fields;
  const blogs = data.blogs.edges.map((edge) => ({
    ...edge.node.frontmatter,
    slug: edge.node.fields.slug,
    readTime: edge.node.fields.readingTime.text
  }));
  const featuredBlog = !!featured.frontmatter.title
    ? {
        ...featured.frontmatter,
        slug: featured.fields.slug
      }
    : blogs[0];
  const lastResources = data.lastResources.edges.map((edge) => ({
    ...edge.node.frontmatter,
    slug: edge.node.fields.slug
  }));

  return (
    <BlogetryPageTemplate
      featured={featuredBlog}
      blogs={blogs}
      lastResources={lastResources}
    />
  );
};

BlogetryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default BlogetryPage;

export const pageQuery = graphql`
  query BlogetryPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Blogetry/index" } }) {
      fields {
        featured {
          fields {
            slug
          }
          frontmatter {
            title
            date
            category
            coverImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
      frontmatter {
        templateKey
        featuredBlog
      }
    }
    blogs: allMarkdownRemark(
      filter: { published: { eq: true }, frontmatter: { templateKey: { eq: "Blog/index" } } }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
            coverImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
    lastResources: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "Resource/index" } } }
      limit: 4
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            url
            category
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
