import React from 'react';

import './index.css';
import logo from '../../../img/blogetry-hero.svg'
import zebra from '../../../img/blogetry-zebra.svg'

export default () => {
  return (
    <section className="blogetry-hero">
      <img
        className="logo"
        src={logo}
        alt="Hero"
      />
      <img
        className="zebra"
        src={zebra}
        alt="Hero Background"
      />
    </section>
  );
};
