import React, { useRef, useEffect, useState } from 'react';

import './index.css';
import SelectionBar from '../../SelectionBar';
import BlogCard from '../../BlogCard';
import PaginatedContent from '../../Pagination';

const availableCategories = [
  'Product',
  'Design',
  'Development',
  'Leadership',
  'Devetry'
];
const margin = 30;

export default ({ items }) => {
  const ref = useRef();
  const [selectionBarItems, setSelectionBarItems] = useState([]);
  const [updatePage, setUpdatePage] = useState(false);
  const [currentCat, setCurrentCat] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const categoriesToShow = ['All posts'];
    availableCategories.forEach((category) => {
      const showCat = items.find((item) => item.category === category);
      if (showCat) categoriesToShow.push(category);
    });
    setSelectionBarItems(categoriesToShow);
  }, []);

  useEffect(() => {
    if (!currentCat) setCurrentItems([]);
    else filterByCategory(selectionBarItems[currentCat]);
    setUpdatePage(true);
  }, [currentCat]);

  const scrollToTop = () => window.scrollTo(0, ref.current.offsetTop - margin);

  const filterByCategory = (category) => {
    setCurrentItems(items.filter((item) => item.category === category));
  };

  const handlePageChange = () => {
    scrollToTop();
    setUpdatePage(false);
  };

  return (
    <section ref={ref} className="blogetry-blog">
      <SelectionBar
        currentCategory={currentCat}
        setCurrentCategory={setCurrentCat}
        items={selectionBarItems}
      />
      <div className="blog-card-container secondary-padding">
        <PaginatedContent
          resetPagination={updatePage}
          items={currentCat ? currentItems : items}
          itemsPerPage={6}
          onPageChange={handlePageChange}
          RenderItem={BlogCard}
        />
      </div>
    </section>
  );
};
