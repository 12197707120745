import React from 'react';

import { Link } from 'gatsby';

import { TButton } from '../../Button';
import BlogCard from '../../BlogCard';
import './index.css';
import webinar from '../../../img/webinar.svg';
import tool from '../../../img/tool.svg';
import guide from '../../../img/guide.svg';
import report from '../../../img/report.svg';

const resourceIcons = {
  Webinars: webinar,
  Tools: tool,
  Guides: guide,
  Reports: report,
}

export default ({ data, lastResources }) => {
  return (
    <section className="blogetry-featured secondary-padding">
      <div className="content">
        <div className="left">
          <h1>Featured post</h1>
          <BlogCard item={data} className="featured" />
        </div>
        <div className="border border-mobile" />
        <div className="right">
          <div className="top">
            <p>Downloadable Content</p>
            <Link to="/resources" className="see-all-resources">
              <TButton text="see all" />
            </Link>
          </div>
          <div className="bottom">
            {lastResources &&
              lastResources.length &&
              lastResources.map(({ title, url, category }, indx) => (
                <a href={url} target="_blank" rel="noreferrer" key={`key-${indx}`}>
                  <div className="downloadable-card">
                    <div className="left-dots">
                      <div />
                      <div />
                      <div />
                    </div>
                    <p>{title}</p>
                    <div className="right">
                      <p className="type">{category}</p>
                      <img
                        src={resourceIcons[category]}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
      <div className="border border-desktop" />
    </section>
  );
};
