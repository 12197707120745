import React, { Fragment, useState } from 'react';

import './index.css';
import chevronWhite from '../../img/chevron-down-white.svg'

export default ({
  currentCategory = 0,
  setCurrentCategory,
  items = [],
  lightTheme
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Fragment>
      <div className="selection-bar-blogetry secondary-padding">
        <p className="left">{items[currentCategory]}</p>
        <div className="right">
          {items.map((item, index) => (
            <Fragment key={index}>
              <p
                onClick={() => setCurrentCategory(index)}
                className={`category ${
                  currentCategory === index ? 'active' : ''
                }`}
              >
                {item}
              </p>
              {index !== items.length - 1 && <div className="divider">/</div>}
            </Fragment>
          ))}
        </div>
      </div>
      <div
        className="selection-bar"
        style={
          lightTheme
            ? {
                backgroundColor: 'transparent',
                color: 'var(--primary)',
                justifyContent: 'flex-start'
              }
            : {}
        }
      >
        {items.map((item, index) => (
          <Fragment key={index}>
            <p
              onClick={() => setCurrentCategory(index)}
              className={currentCategory === index ? 'active' : ''}
            >
              {item}
            </p>
            {index !== items.length - 1 && <div className="divider">/</div>}
          </Fragment>
        ))}
      </div>
      <div
        onClick={() => setMenuOpen(!menuOpen)}
        className={`mini-selection-bar root-padding ${menuOpen ? 'open' : ''}`}
      >
        <p> {`Category: ${items[currentCategory]}`}</p>
        <img
          className={menuOpen ? 'active' : ''}
          src={chevronWhite}
          alt="Chevron"
        />
        <div className={`c-menu ${menuOpen ? 'open' : ''}`}>
          {items.map(
            (cat, index) =>
              index !== currentCategory && (
                <p
                  className="menu-items"
                  key={index}
                  onClick={() => setCurrentCategory(index)}
                >
                  {`Category: ${cat}`}
                </p>
              )
          )}
        </div>
      </div>
    </Fragment>
  );
};
