import React, { useState, useEffect } from 'react';
import ArrowButtonText from '../ArrowButtonText';

import './index.css';

const Pagination = ({
  items,
  itemsPerPage,
  resetPagination,
  onPageChange,
  RenderItem
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (resetPagination) setCurrentPage(0);
  }, [resetPagination]);

  const handleChangePage = (index) => {
    setCurrentPage(index);
    if (onPageChange) onPageChange();
  };

  const renderContent = () => {
    const renderArray = [];

    for (
      let index = currentPage * itemsPerPage;
      index < currentPage * itemsPerPage + 6;
      index++
    ) {
      let blog;

      if (items.length && items[index]) {
        blog = items[index];
      }

      if (blog) {
        renderArray.push(
          <RenderItem
            key={index}
            item={blog.node ? blog.node.frontmatter : blog}
          />
        );
      }
    }
    return renderArray;
  };

  const renderPagination = () => {
    let pages = [];
    let contPages = Math.ceil(items.length / itemsPerPage);
    let currentIndex = currentPage;
    //Only show Previous if not on first page
    if(currentIndex !== 0){
      pages.push(
        <>
          <div className="pagination-button-wrapper" onClick={() => handleChangePage(currentIndex-1)}>
            <ArrowButtonText/>
            <p className="pagination-button prev-button">Prev</p>
          </div>
        </>
      );
    }
   
    let startPaginationIndex = currentIndex - 5;
    if(startPaginationIndex < 0){
      startPaginationIndex = 0;
    }
    let endPaginationIndex = startPaginationIndex + 11;
    if(endPaginationIndex > contPages){
      endPaginationIndex = contPages
    }
    for (let index = startPaginationIndex; index < endPaginationIndex; index++) {
      pages.push(
        <p
          key={index}
          onClick={() => handleChangePage(index)}
          className={currentPage === index ? 'active' : ''}
        >
          {index + 1}
        </p>
      );
    }
    //Only show Next button if not on last page
    if(currentIndex !== contPages -1 ){
      pages.push(
        <>
          <div className="pagination-button-wrapper" onClick={() => handleChangePage(currentIndex+1)}>
            <p className="pagination-button next-button">Next</p>
            <ArrowButtonText />
          </div>
        </>
      );
    }
    return pages;
  };

  return (
    <div className="pagination">
      <div className="content">{renderContent()}</div>
      <div className="pages">{renderPagination()}</div>
    </div>
  );
};

export default Pagination;
